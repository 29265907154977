<template>
  <v-container fluid >
    <h4 class="color-primary">{{ type === 'parent' ? $lang.labels.parentRelations : $lang.labels.childRelations }}</h4>
    <v-data-table
      v-if="finishInit && items.length > 0"
      :headers="headers"
      :items="items"
      item-key="entityId"
      class="elevation-0 background-transparent pa-0"
      hide-default-footer
      :items-per-page="-1"
    >
      <template v-slot:item.entityId="{ item }">
        <div>{{ formatEntity(item.entityId) }}</div>
      </template>

      <template v-slot:item.type="{ item }">
        <div>{{ $lang.status[item.type] }}</div>
      </template>

      <template v-slot:item.actions="{ item }">
        <span v-if="item.entityId" class="color-info clickable-simple" @click="openEntity(item.entityId)">Open</span>
      </template>
    </v-data-table>
    <v-alert
      v-else-if="finishInit && items.length === 0"
      type="info"
      class="mt-4"
    >
      {{ type === 'parent' ? $lang.hints.noParentRelationsToShow : $lang.hints.noChildRelationsToShow }}
    </v-alert>
  </v-container>
</template>

<script>
import { getEntityByIdUsingGET as getEntity } from '@/utils/api'

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: () => 'parent'
    }
  },
  data () {
    return {
      lock: true,
      fetchedEntities: [],
      headers: [
        { text: this.$lang.labels.entity, value: 'entityId', align: 'start', sortable: true },
        { text: this.$lang.labels.field, value: 'field' },
        { text: this.$lang.labels.type, value: 'type' },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ],
      finishInit: false
    }
  },
  watch: {
    items: {
      handler () {
        this.init()
      },
      deep: true
    }
  },
  created() {
    this.init()
    setTimeout(() => this.lock = false, 100)
  },
  methods: {
    openEntity(id) {
      window.open(`/${localStorage.selectedLanguage || 'en'}/entities/edit/${id}`, '_blank')
    },
    async init() {
      this.finishInit = false

      const itemsEntities = this.items.map((x) => x.entityId)

      const newEntityIdsUnique = [...new Set(itemsEntities)]

      if (newEntityIdsUnique.length === 0) {
        this.finishInit = true

        return
      }

      const fetchedEntities = newEntityIdsUnique.map(async (id) => {
        return this.fetchEntity(id)
      })

      await Promise.all(fetchedEntities)
        .then((result) => {
          this.fetchedEntities = result

          this.finishInit = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    formatEntity(id) {
      const findById = this.fetchedEntities.find((entity) => entity.id === id)

      if (findById) return findById.name

      return ''
    },
    async fetchEntity(id) {
      return new Promise((resolve, reject) => {
        getEntity({ id })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch(() => {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(false)
          })
      })
    }
  }
}
</script>
