<template>
  <v-card class="pa-2" style="min-height: 80vh;">
    <v-card-title class="pb-2">
      <h3>{{ $lang.labels.resources }} {{ $lang.header[type] }}</h3>
      <v-spacer></v-spacer>
      <v-btn
        icon
        color="primary"
        text
        large
        @click="$emit('closeDialog', true)"
      >
        X
      </v-btn>
    </v-card-title>
    <v-col no-gutters cols="12" class="pa-4">
      <v-col cols="12" md="12">
        <div style="width: 100%;" class=" custom-validate-table-border">
          <h3 class=" pa-2 custom-generation-border-bottom">{{ $lang.labels.generated }}:</h3>
          <div v-if="data && data.data">
            <v-data-table
              :headers="headersGenerated"
              :items="formattedData"
              item-key="id"
              hide-default-footer
              class="elevation-0 background-transparent"
              :items-per-page="-1"
            >

              <template v-slot:item.isSystem="{ item }">
                <div v-if="item.generated && item.generated.isSystem === true" class="font-weight-bold">{{ $lang.labels.yes }}</div>
                <div v-if="item.generated && item.generated.isSystem === false" class="font-weight-bold">{{ $lang.labels.no }}</div>
                <div v-if="!item.generated || (item.generated && item.generated.isSystem === undefined)" class="font-weight-bold">N/A</div>
              </template>

              <template v-slot:item.resourceId="{ item }">
                <div v-if="item.resourceId" class="font-weight-bold"># {{ item.resourceId }}</div>
              </template>

              <template v-slot:item.resourceType="{ item }">
                <div>{{ item.resourceActionGenerated ? $lang.status[item.resourceType] : '' }}</div>
              </template>

              <template v-slot:item.resourceActionGenerated="{ item }">
                <div>{{ $lang.status[item.resourceActionGenerated] }}</div>
              </template>

              <template v-slot:item.conflict="{ item }">
                <div :class="{ 'color-error': item.conflict }">{{ item.conflict ? $lang.labels.yes : $lang.labels.no }}</div>
              </template>

              <template v-slot:item.actions="{ item }">
                <div class="d-inline-flex">
                  <span v-if="item.generated && item.generated.id" class="color-info clickable-simple" @click="openResource(item, 'generated')">Open</span>
                </div>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="12" class="pt-5">
        <div style="width: 100%;" class="custom-validate-table-border">
          <h3 class="pa-2 custom-generation-border-bottom">{{ $lang.labels.existing }}:</h3>
          <div v-if="data && data.data">
            <v-data-table
              :headers="headersExisting"
              :items="formattedData.filter(item => item.existing)"
              item-key="id"
              hide-default-footer
              class="elevation-0 background-transparent"
              :items-per-page="-1"
            >

              <template v-slot:item.isSystem="{ item }">
                <div v-if="item.existing && item.existing.isSystem === true" class="font-weight-bold">{{ $lang.labels.yes }}</div>
                <div v-if="item.existing && item.existing.isSystem === false" class="font-weight-bold">{{ $lang.labels.no }}</div>
                <div v-if="!item.existing || (item.existing && item.existing.isSystem === undefined)" class="font-weight-bold">N/A</div>
              </template>

              <template v-slot:item.resourceId="{ item }">
                <div v-if="item.resourceId" class="font-weight-bold"># {{ item.resourceId }}</div>
              </template>

              <template v-slot:item.resourceType="{ item }">
                <div>{{ item.resourceActionExisting ? $lang.status[item.resourceType] : '' }}</div>
              </template>

              <template v-slot:item.resourceActionExisting="{ item }">
                <div>{{ $lang.status[item.resourceActionExisting] }}</div>
              </template>

              <template v-slot:item.conflict="{ item }">
                <div :class="{ 'color-error': item.conflict }">{{ item.conflict ? $lang.labels.yes : $lang.labels.no }}</div>
              </template>

              <template v-slot:item.actions="{ item }">
                <div class="d-inline-flex">
                  <span v-if="item.existing && item.existing.id" class="color-info clickable-simple" @click="openResource(item, 'existing')">Open</span>
                </div>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-col>
    </v-col>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    type: {
      type: String,
      default: 'VALIDATE'
    },
    errorOrSuccess: {
      type: String,
      default: 'SUCCESS'
    },
    data: {
      type: Object,
      default: () => null
    },
    message: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      finishInit: false,
      headersGenerated: [
        {
          text: this.$lang.labels.name,
          align: 'start',
          sortable: true,
          value: 'generated.name'
        },
        { text: this.$lang.labels.isSystem, value: 'isSystem', sortable: false },
        { text: this.$lang.labels.resourceType, value: 'resourceType', sortable: true },
        { text: this.$lang.labels.resourceAction, value: 'resourceActionGenerated', sortable: true },
        { text: this.$lang.labels.resourceId, value: 'generated.id', sortable: true },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ],
      headersExisting: [
        {
          text: this.$lang.labels.name,
          align: 'start',
          sortable: true,
          value: 'existing.name'
        },
        { text: this.$lang.labels.isSystem, value: 'isSystem', sortable: false },
        { text: this.$lang.labels.resourceType, value: 'resourceType', sortable: true },
        { text: this.$lang.labels.resourceAction, value: 'resourceActionExisting', sortable: true },
        { text: this.$lang.labels.resourceId, value: 'existing.id', sortable: true },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ]
    }
  },
  computed: {
    formattedData() {
      if (this.data && this.data.data) {

        return this.data.data.map((item) => {
          return {
            ...item,
            resourceActionGenerated: this.findResourceActionFromName(item.generated ? item.generated.name : ''),
            resourceActionExisting: this.findResourceActionFromName(item.existing ? item.existing.name : '')
          }
        })
      }

      return []
    }
  },
  created() {
    if (this.errorOrSuccess === 'SUCCESS' && this.message) {
      this.addSnackbar({
        message: this.message,
        timeout: 5000,
        color: 'success'
      })
    } else if (this.message) {
      this.addSnackbar({
        message: this.message,
        timeout: 5000,
        color: 'error'
      })
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),
    findResourceActionFromName(name) {
      if (name.includes('CREATE')) {
        return 'CREATE'
      } else if (name.includes('UPDATE')) {
        return 'UPDATE'
      } else if (name.includes('DELETE')) {
        return 'DELETE'
      } else if (name.includes('FIND_BY_ID')) {
        return 'FIND_BY_ID'
      } else if (name.includes('SEARCH')) {
        return 'SEARCH'
      } else if (name.includes('validation') && name.includes('BODY')) {
        return 'VALIDATION_BODY'
      } else if (name.includes('validation') && name.includes('ID')) {
        return 'VALIDATION_PATH'
      }

      return ''
    },
    openResource(resource, type) {
      this.$emit('openResource', { resource, type })
    }
  }
}
</script>
<style lang="scss">

.custom-scroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.custom-scroll {
  /* Enable scrolling */
  overflow: auto;

  /* Hide scrollbar for all browsers */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
}
</style>
