<template>
  <v-row no-gutters>
    <v-checkbox
      :input-value="isPersistenceDeleteChecked"
      :label="$lang.labels.deletePersistence"
      :data-cy="`${entityId}-delete-persistence-option`"
      class="mt-0 option-checkbox"
      @change="togglePersistenceChecked"
    ></v-checkbox>
    <v-checkbox
      :input-value="isResourcesDeleteChecked"
      :label="$lang.labels.deleteResources"
      class="ml-4 mt-0 option-checkbox"
      :data-cy="`${entityId}-delete-resources-option`"
      @change="toggleResourcesChecked"
    ></v-checkbox>
  </v-row>
</template>

<script>
export default {
  name: 'EntityDeleteOptions',
  props: {
    entityId: {
      type: Number,
      default: () => null
    }
  },
  data() {
    return {
      isPersistenceDeleteChecked: false,
      isResourcesDeleteChecked: false
    }
  },
  methods: {
    togglePersistenceChecked(val) {
      this.isPersistenceDeleteChecked = val
      this.$emit('passData', {
        isPersistenceDeleteChecked: val,
        isResourcesDeleteChecked: this.isResourcesDeleteChecked
      })
    },
    toggleResourcesChecked(val) {
      this.isResourcesDeleteChecked = val
      this.$emit('passData', {
        isPersistenceDeleteChecked: this.isPersistenceDeleteChecked,
        isResourcesDeleteChecked: val
      })
    }
  }
}
</script>

<style scoped>
  .option-checkbox >>> .v-label {
    font-size: 0.8rem;
  }
</style>
